<template>
  <b-col
    lg="6"
    md="6"
  >
    <b-card
      no-body
      class="card-developer-meetup"
    >
      <b-card-header>
        <b-card-title>Legenda pitanja:</b-card-title>
      </b-card-header>
      <b-card-body>
        <b-media
          v-for="media in mediaData"
          :key="media.avatar"
          no-body
        >
          <b-media-aside>
            <b-avatar
              rounded
              text="T"
              :variant="media.variant"
              size="30"
            />
          </b-media-aside>
          <b-media-body>
            <h6>
              {{ media.title }}
            </h6>
          </b-media-body>
        </b-media>
      </b-card-body>
    </b-card>
  </b-col>
</template>

<script>
import {
  BCol, BCard, BCardHeader, BCardTitle, BCardBody, BMedia, BMediaBody, BMediaAside, BAvatar,
} from 'bootstrap-vue'

export default {
  props: {
    mediaData: {
      type: Array,
      default: null,
    },
  },
  components: {
    BCol, BCard, BCardHeader, BCardTitle, BCardBody, BMedia, BMediaAside, BAvatar, BMediaBody,
  },
}
</script>

<template>
  <b-col
    lg="8"
    md="8"
  >
    <b-card
      class="card-transaction"
      no-body
    >
      <b-card-header>
        <b-card-title>{{ pitanje.text }}</b-card-title>
      </b-card-header>
      <b-card-body>
        <div
          v-for="(odgovor, key) in odgovori"
          :key="odgovor.mode"
          class="transaction-item"
          style="cursor: pointer;"
          @click="klikOdgovor(odgovor, key)"
        >
          <b-media no-body>
            <b-media-aside>
              <b-avatar
                :text="odgovor.rb"
                rounded
                size="42"
                :variant="odgovor.variant"
              />
            </b-media-aside>
            <b-media-body>
              <h6 class="transaction-title">
                {{ odgovor.text }}
              </h6>
            </b-media-body>
          </b-media>
        </div>
        <div class="demo-inline-spacing">
          <b-button
            size="sm"
            variant="primary"
          >
            Odgovori
          </b-button>
          <b-button
            size="sm"
            variant="primary"
          >
            Sledece
          </b-button>
        </div>
      </b-card-body>
    </b-card>
  </b-col>
</template>

<script>
import {
  BCol, BCard, BCardHeader, BCardTitle, BCardBody, BMedia, BMediaAside, BAvatar, BMediaBody, BButton,
} from 'bootstrap-vue'

export default {
  components: {
    BCol, BCard, BCardHeader, BCardTitle, BCardBody, BMedia, BMediaAside, BAvatar, BMediaBody, BButton,
  },
  props: {
    pitanje: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      oznakeOdgovora: ['A', 'B', 'C', 'D', 'G'],
    }
  },
  computed: {
    odgovori() {
      const el = []
      if (typeof this.pitanje.odgovori !== 'undefined') {
        this.pitanje.odgovori.forEach((element, index) => {
          const odg = element
          odg.rb = this.oznakeOdgovora[index]
          odg.variant = 'primary'
          el[index] = odg
        })
      }
      return el
    },
  },
  methods: {
    klikOdgovor(odgovor, key) {
      this.odgovori[key].variant = 'success'
    },
  },
}
</script>

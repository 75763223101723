<template>
  <b-col
    lg="6"
    md="6"
  >
    <div
      class="demo-inline-spacing"
    >
      <b-button
        size="sm"
        variant="primary"
      >
        Zavrsi vezbu
      </b-button>
      <b-button
        size="sm"
        variant="primary"
      >
        Statistika
      </b-button>
      <b-button
        size="sm"
        variant="primary"
      >
        Nova vezba
      </b-button>
      <b-button
        size="sm"
        variant="primary"
      >
        Prijavi gresku
      </b-button>
    </div>
  </b-col>
</template>

<script>
import { BButton, BCol } from 'bootstrap-vue'

export default {
  components: {
    BButton, BCol,
  },
}
</script>

<template>
  <div>
    <b-row>
      <b-col
        lg="12"
        md="6"
      >
        <b-card>
          <b-card-text>
            Vreme: {{ vreme }}
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        lg="4"
        md="4"
      >
        <b-card
          overlay
          :img-src="require('@/assets/images/slider/slika_421.jpeg')"
          img-alt="overlay img"
          class="position-static"
        />
      </b-col>
      <Pitanje
        :pitanje="pitanje"
      />
    </b-row>
    <b-row>
      <b-col
        lg="12"
        md="12"
      >
        <kockice-redni-broj />
      </b-col>
    </b-row>
    <b-row>
      <Legenda
        :media-data="mediaData"
      />
      <Statistika
        :pitanja-stat-podaci="pitanjaStatPodaci"
      />
    </b-row>
    <b-row>
      <Footer />
    </b-row>
  </div>

</template>

<script>
import {
  BCard,
  BCardText,
  BCol,
  BRow,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import KockiceRedniBroj from './KockiceRedniBroj.vue'
import Footer from './Footer.vue'
import Legenda from './Legenda.vue'
import Statistika from './Statistika.vue'
import Pitanje from './Pitanje.vue'

export default {
  components: {
    BCard,
    BCardText,
    BCol,
    BRow,
    KockiceRedniBroj,
    Footer,
    Legenda,
    Statistika,
    Pitanje,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      timer: 0,
      vreme: '',
      transactionData: [
        {
          mode: 'nije dozvoljeno,',
          types: 'Starbucks',
          avatar: 'HelpCircle',
          avatarVariant: 'light-primary',
          payment: '-$74',
          deduction: true,
          rb: 'A',
        },
        {
          mode: 'je dozvoljeno,',
          types: 'Add Money',
          avatar: 'CheckIcon',
          avatarVariant: 'light-success',
          payment: '+$480',
          deduction: false,
          rb: 'B',
        },
        {
          mode: 'je dozvoljeno ukoliko slobodan prostor omogućava prolaz vozila.',
          types: 'Add Money',
          avatar: 'DollarSignIcon',
          avatarVariant: 'light-danger',
          payment: '+$480',
          deduction: false,
          rb: 'C',
        },
      ],
      pitanjaStatPodaci: [
        {
          mode: 'ID pitanja',
          types: 'Starbucks',
          avatar: 'HelpCircle',
          avatarVariant: 'light-primary',
          payment: '342',
          deduction: true,
          rb: 'A',
        },
        {
          mode: 'Oblast pitanja',
          types: 'Add Money',
          avatar: 'CheckIcon',
          avatarVariant: 'light-success',
          payment: '1',
          deduction: false,
          rb: 'B',
        },
        {
          mode: 'Broj pitanja iz fajla',
          types: 'Add Money',
          avatar: 'DollarSignIcon',
          avatarVariant: 'light-danger',
          payment: '231',
          deduction: false,
          rb: 'C',
        },
        {
          mode: 'Broj bodova ',
          types: 'Add Money',
          avatar: 'DollarSignIcon',
          avatarVariant: 'light-danger',
          payment: '10',
          deduction: false,
          rb: 'C',
        },
      ],
      pitanje: {},
      brojeviPitanja: [1, 2, 3, 4, 5, 6, 7],
      mediaData: [
        { title: 'Trenutno', variant: 'primary' },
        { title: 'Tačno', variant: 'success' },
        { title: 'Netačno', variant: 'danger' },
        { title: 'Neodgovoreno', variant: 'warning' },
      ],
    }
  },
  created() {
    setInterval(this.izracunajVreme, 1000)
  },
  mounted() {
    this.pokupiListu()
    this.izracunajVreme()
  },
  methods: {
    izracunajVreme() {
      const date = new Date(null)
      date.setSeconds(this.timer)
      this.vreme = date.toISOString().substr(11, 8)
      this.timer += 1
    },
    pokupiListu() {
      this.$http.get('/instruktori/pitanja/6288').then(response => {
        this.pitanje = response.data
      })
    },
  },
}
</script>

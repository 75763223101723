<template>
  <b-col
    lg="6"
    md="6"
  >
    <b-card
      class="card-transaction"
      no-body
    >
      <b-card-body>
        <div
          v-for="transaction in pitanjaStatPodaci"
          :key="transaction.mode"
          class="transaction-item"
        >
          <b-media no-body>
            <b-media-body>
              <h6 class="transaction-title">
                {{ transaction.mode }}
              </h6>
            </b-media-body>
          </b-media>
          <div class="font-weight-bolder text-success">
            {{ transaction.payment }}
          </div>
        </div>
      </b-card-body>
    </b-card>
  </b-col>
</template>

<script>
import {
  BCol, BCard, BCardBody, BMedia, BMediaBody,
} from 'bootstrap-vue'

export default {
  props: {
    pitanjaStatPodaci: {
      type: Array,
      default: null,
    },
  },
  components: {
    BCol, BCard, BCardBody, BMedia, BMediaBody,
  },
}
</script>
